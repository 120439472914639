body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8eaed;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d5d9de;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #697481;
}

.scrollbar-invisible::-webkit-scrollbar {
  display: none;
}
.Toastify__toast {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  box-shadow: none;
}

.basicTransition-enter {
  opacity: 0;
  /*transform: scale(0.9);*/
}
.basicTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.basicTransition-exit {
  opacity: 1;
}
.basicTransition-exit-active {
  opacity: 0;
  transform: scale(0.01);
  transition: opacity 300ms, transform 500ms;
}

.ui.menu {
  border-radius: 6px !important;
  box-shadow: none;
}
